@import 'common-flagship_tasting_room';
@import 'common-non_commerce';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
	color: inherit;
}

.b-header-background {
	border-bottom: 1px solid $color-secondary;
}
.b-categories_navigation {
	 &-item_1:after {
		border-bottom: 3px solid $color-secondary;
 	}
	&-group_1 {
		border-top: 1px solid $color-secondary;
		.t-link_1 , .t-heading_6 {
			color: $color-secondary-text;
		}
	}
}

.b-categories_navigation-list_1 {
	color: $color-primary;
	font-family: $font-alt;
	font-size: 16px;
	letter-spacing: 0.3px;
	line-height: 24px;
	font-weight: 200 !important;
}

.b-categories_navigation-link_1, .b-header-navigation_link.m-store {
	line-height: 40px;
}

.b-categories_navigation-link_2 , .b-categories_navigation-link_3.m-view_all {
	color: $color-primary;
}

.b-product {
	&_tile-name {
		color: $color-secondary-text;
	}
	&_price-value {
		font-weight: 300;
	}
}

.b-product_gallery-thumb.m-current:after{
    border-color: $color-secondary !important;
}

.b-breadcrumbs-link.m-current {
	color: var(--color-link, $color-primary) !important;
}

.b-breadcrumbs {
	&-link {
		color: var(--color-link, $color-secondary-text) !important;
	}
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-breadcrumb-separator_bg;
                    margin: 2px 15px 0;
                }
            }
        }
    }
}

.b-footer-contacts .content-asset {
	@include media(sm) {
		padding-bottom: 0;
	}
}
.b-footer {
	&_legal-link {
		font-size: $configuration-global_footer;
	}
	&-customer_service , &-need_help{
		visibility: hidden;
	}
	&-bottom_info, &_legal-links {
		@include media(sm) {
			align-items: center;
		}
	}
	&-copyright {
		color: $color-primary !important;
		opacity: initial;
		font-size: $configuration-global_footer;
	}
	&-social {
		@include media(md) {
			grid-area: auto !important;
		}
	}
	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		text-align: left;
		&-title {
			text-transform: capitalize;
			color: $color-primary !important;
			font: $font-main;
			@include media(sm) {
				text-align: center;
			}
		}
		&-links {
			justify-content: flex-start;
		   & a {
				padding-right: 16px;
			}
			@include media(sm) {
				justify-content: center;
			}
		}
	}
	@include media(md-down) {
		background: transparent;
	}
	background: transparent;
	border-top: 1px solid $color-shade_5;
	&_navigation {
		&-content {
			@include media(md-down) {
				align-items: center;
				letter-spacing: 0.4px;
			}
		}
		&-title {
			text-transform: capitalize;
			color: $color-primary !important;
			font: $font-main;
			@include media(md-down) {
				text-align: center;
			}
		}
	}

}
.b-footer_navigation-link {
	font-size: 14px !important;
}
.b-search_input-input {
    font-weight: 400;
}
.logo {
	&-desktop {
		margin-top: 5%;
		@include media(sm){
			display: none;

		}
		img {
			width: 100%;
		}
	}
	&-mobile {
		display: none;
		margin-top: 5%;
		@include media(sm){
			display: block;
		}
		img {
			width: 100%;
		}
	}
}

.b-header.m-stuck .logo-desktop {
	@include media(sm){
		display: none;
	}

	display: block;
}
.b-header.m-stuck .logo-mobile {
	@include media(sm){
		display: block;
	}
}

.f-input_checkbox input , .f-input_radio-field , .f-input_radio-field.m-checked {
	background: $color-secondary;
}
.b-refinements_accordion-button:after {
    background: var(--icon-color,$color-secondary) !important;
}
.b-refinement_list-more {
	color: $color-secondary;
}
.b-refinements_accordion-button {
	font-size: 16px !important;
}

.l-plp-title {
	font-size: 36px !important;
	line-height: 48px !important;
}

.l-products_results {
	&-sorting {
		@include media(md-down){
			grid-template-columns: 1fr 1fr;
		}
	}

}

.l-products_results-refinement_button {
	background: $color-secondary !important;
}

.b-product_badge {

	&.m-promo {
		background: $color-secondary;
	}
	color: $color-primary-background !important;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url("./svg-icons/badge-designlogo.svg");
		background-repeat: round;
		border-radius: 50%;
		font-family: $font-alt;
		font-size: 20px;
		letter-spacing: 0.5px;
		line-height: 32px;
		@include media(sm) {
			background-image: url("./svg-icons/badge-designlogo.svg");
			background-repeat: round;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}
	&-points {
		color: $color-shade_7;
        font-family: $font-main;
        font-size: 20px !important;
        letter-spacing: 0.5px;
        line-height: 32px;
		@include media(md-down) {
            line-height: 15px;
        }
	}
	&-text {
        font-size: 12px !important;
        letter-spacing: 0.2px;
        line-height: 18px;
        color:$color-shade_5;
    }
}

.b-carousel-control {
    border: 0;
}

.b-variations_item-label {
    color: $color-shade_3 !important;
}

// subheading in homepage
.subhead_ {
	font-family: $font-main;
	font-size: 28px;
	letter-spacing: 0.5px;
	line-height: 40px;
	color: $color-shade_7;
	font-weight: 200;
}

.b-carousel-title {
  font-family: $font-alt;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;
  margin-left:25px;
}

.mainhead {
	font-family: $font-alt;
	font-size: 48px;
	letter-spacing: 0.5px;
	line-height: 56px;
	text-align: center;
	font-weight: 100;
    margin-bottom: 56px;
}

.b-header-links_item:before, .b-header-utility_item:before {
	color: $color-secondary;
}

.b-store_locator-title {
	font-family: $font-alt;
	font-size: 36px;
	letter-spacing: 0.3px;
	line-height: 48px;
}

.b-header_promo-content {
	padding: 12px 0;
	background: $color-shade_8;
	color: $color-sort;
	font-family: $font-main;
	font-size: 16px;
	letter-spacing: 0.4px;
	line-height: 24px;
	& a {
		color: $color-secondary;
	}
}

.b-carousel {
    &-control{
        border: 0;
        --icon-color: var(--carousel-control-color, #{$color-carousel_control});
        &[disabled] {
            --icon-color: var(--carousel-control-color, #{$color-carousel_control__disabled});
            opacity: initial;
            }
    }
    &-pagination_control{
        border: 1px solid $color-secondary;
    }
}


.f-input_text-label span{
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
	@include media(sm){
		max-width: 290px;
	}
}

.b-customer_service_info-icon {
	color:$color-secondary;
}
.b-tab_list-tab {
    letter-spacing: 0.3px;
}

section.b-account_benefits {
	&.m-with_icons  {
		li.b-account_benefits-item {
			&:before {
				background: $color-primary-background;
			}
		}
	}
}

.b-text_box{
	h1,h2,h3,h4,h5,h6{
		font-weight: 600;
	}
}
.t-heading_1,.t-heading_2,.t-heading_3,.t-heading_4,.t-heading_5,.t-heading_6{
	font-weight: 600;
}
h1,h2,h3,h4,h5,h6{
	font-weight: 600;
}